import React from 'react';
import Helmet from 'react-helmet';
import { Pie, Line } from 'react-chartjs-2';
import axios from 'axios';
import numeral from 'numeral';
import 'chartjs-plugin-piechart-outlabels';

import Layout from '../components/layout';
import reserveAllocation from './reserveAllocation.json';

const pieData = {
  labels: [
    'Community',
    'Team',
    'Founder',
    'Advisers',
    'Private sale',
    'Strategic partner',
    'Great Harbour',
    'Ecosystem',
    'Foundation',
    'Exchanges'
  ],
  datasets: [{
    data: [10489950, 66750000,150000000,25500000,270000000,33333000,180000000,267417000,324000000,172510050],
    backgroundColor: [
      '#FF6384',
      '#36A2EB',
      '#FFCE56',
      '#228B22',
      '#FFC0CB',
      '#FFD700',
      '#7B68EE',
      '#8B4513',
      '#FF4500',
      '#2F4F4F',
    ],
    hoverBackgroundColor: [
      '#FF6384',
      '#36A2EB',
      '#FFCE56',
      '#228B22',
      '#FFC0CB',
      '#FFD700',
      '#7B68EE',
      '#8B4513',
      '#FF4500',
      '#2F4F4F'
    ]
  }]
};

const pieOptions = {
  plugins: {
    datalabels: {
      display: false
    },
    outlabels: {
      display: true,
      padding: {
        top: 3,
        bottom: 3,
        left: 7,
        right: 7
      },
      lineWidth: 1,
      stretch: 20,
      borderRadius: 5,
      formatter: function(value, ctx) {
        const dataArr = ctx.chart.data.datasets[0].data;
        const sum = dataArr.reduce((total, val) => total + val, 0);

        return `${(value * 100 / sum).toFixed(2)}%`;
      },
    }
  },
  legend: false,
  layout: {
    padding: {
      top: 60,
      bottom: 30,
      left: 90,
      right: 90
    }
  },
  maintainAspectRatio: false
}

const lineData = {
  labels: [...Array(48).keys()].map(i => i + 1),
  datasets: [
    {
      label: 'Community',
      fill: true,
      lineTension: 0.1,
      backgroundColor: 'rgba(75,192,192,0.5)',
      borderColor: 'rgba(75,192,192,1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBackgroundColor: 'rgba(75,192,192,1)',
      pointHoverBackgroundColor: 'rgba(75,192,192,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      data: [10.49,10.49,10.49,10.49,10.49,10.49,10.49,10.49,10.49,10.49,10.49,10.49,10.49,10.49,10.49,10.49,10.49,10.49,10.49,10.49,10.49,10.49,10.49,10.49,10.49,10.49,10.49,10.49,10.49,10.49,10.49,10.49,10.49,10.49,10.49,10.49,10.49,10.49,10.49,10.49,10.49,10.49,10.49,10.49,10.49,10.49,10.49,10.49]
    },
    {
      label: 'Team',
      fill: true,
      lineTension: 0.1,
      backgroundColor: 'rgba(255,99,132,0.5)',
      borderColor: 'rgba(255,99,132,1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBackgroundColor: 'rgba(255,99,132,1)',
      pointHoverBackgroundColor: 'rgba(255,99,132,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      data: [0,0,0,0,0,0,0,0,0,0,0,6.6,10.0,13.3,16.6,20.0,23.3,26.7,30.0,33.3,36.7,40.0,43.3,46.7,50.0,53.4,56.7,60.0,63.4,66.7,66.7,66.7,66.7,66.7,66.7,66.7,66.7,66.7,66.7,66.7,66.7,66.7,66.7,66.7,66.7,66.7,66.7,66.7]
    },
    {
      label: 'Founder',
      fill: true,
      lineTension: 0.1,
      backgroundColor: 'rgba(54,162,235,0.5)',
      borderColor: 'rgba(54,162,235,1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBackgroundColor: 'rgba(54,162,235,1)',
      pointHoverBackgroundColor: 'rgba(54,162,235,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      data: [0,0,0,0,0,0,0,0,0,0,0,9.0,9.0,9.0,13.5,13.5,13.5,18.0,18.0,18.0,22.5,22.5,22.5,27.0,27.0,27.0,31.5,31.5,31.5,36.0,36.0,36.0,40.5,40.5,40.5,45.0,45.0,45.0,45.0,45.0,45.0,45.0,45.0,45.0,45.0,45.0,45.0,45.0]
    },
    {
      label: 'F/Investor',
      fill: true,
      lineTension: 0.1,
      backgroundColor: 'rgba(255,206,86,0.5)',
      borderColor: 'rgba(255,206,86,1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBackgroundColor: 'rgba(255,206,86,1)',
      pointHoverBackgroundColor: 'rgba(255,206,86,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      data: [0,0,0,0,0,0,0,0,0,0,0,45.00,45.00,45.00,45.00,45.00,45.00,45.00,45.00,45.00,45.00,45.00,45.00,45.00,45.00,45.00,45.00,45.00,45.00,45.00,45.00,45.00,45.00,45.00,45.00,45.00,45.00,45.00,45.00,45.00,45.00,45.00,45.00,45.00,45.00,45.00,45.00,45.00]
    },
    {
      label: 'Two Plus',
      fill: true,
      lineTension: 0.1,
      backgroundColor: 'rgba(34,139,34,0.5)',
      borderColor: 'rgba(34,139,34,1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBackgroundColor: 'rgba(34,139,34,1)',
      pointHoverBackgroundColor: 'rgba(34,139,34,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      data: [0,0,0,0,0,0,0,0,0,0,0,6.00,6.00,6.00,6.00,6.00,6.00,6.00,6.00,6.00,6.00,6.00,6.00,12.00,12.00,12.00,18.00,18.00,18.00,24.00,24.00,24.00,30.00,30.00,30.00,36.00,36.00,36.00,42.00,42.00,42.00,48.00,48.00,48.00,54.00,54.00,54.00,60.00]
    },
    {
      label: 'Advisers',
      fill: true,
      lineTension: 0.1,
      backgroundColor: 'rgba(255,192,203,0.5)',
      borderColor: 'rgba(255,192,203,1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBackgroundColor: 'rgba(255,192,203,1)',
      pointHoverBackgroundColor: 'rgba(255,192,203,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      data: [0,0,0,0,0,0,0,0,0,0,0,2.55,5.10,7.65,10.20,12.75,15.30,17.85,20.40,22.95,25.50,25.50,25.50,25.50,25.50,25.50,25.50,25.50,25.50,25.50,25.50,25.50,25.50,25.50,25.50,25.50,25.50,25.50,25.50,25.50,25.50,25.50,25.50,25.50,25.50,25.50,25.50,25.50]
    },
    {
      label: 'Private Sale',
      fill: true,
      lineTension: 0.1,
      backgroundColor: 'rgba(255,215,0,0.5)',
      borderColor: 'rgba(255,215,0,1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBackgroundColor: 'rgba(255,215,0,1)',
      pointHoverBackgroundColor: 'rgba(255,215,0,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      data: [0,0,33.75,33.75,33.75,67.50,67.50,67.50,101.25,101.25,101.25,135.00,135.00,135.00,168.75,168.75,168.75,202.50,202.50,202.50,236.25,236.25,236.25,270.00,270.00,270.00,270.00,270.00,270.00,270.00,270.00,270.00,270.00,270.00,270.00,270.00,270.00,270.00,270.00,270.00,270.00,270.00,270.00,270.00,270.00,270.00,270.00,270.00]
    },
    {
      label: 'Liquidity reserve',
      fill: true,
      lineTension: 0.1,
      backgroundColor: 'rgba(123,104,238,0.5)',
      borderColor: 'rgba(123,104,238,1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBackgroundColor: 'rgba(123,104,238,1)',
      pointHoverBackgroundColor: 'rgba(123,104,238,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      data: [75.00,75.00,75.00,75.00,75.00,75.00,75.00,75.00,75.00,75.00,75.00,75.00,75.00,75.00,75.00,75.00,75.00,75.00,75.00,75.00,75.00,75.00,75.00,75.00,75.00,75.00,75.00,75.00,75.00,75.00,75.00,75.00,75.00,75.00,75.00,75.00,75.00,75.00,75.00,75.00,75.00,75.00,75.00,75.00,75.00,75.00,75.00,75.00]
    },
    {
      label: 'Strategic partner',
      fill: true,
      lineTension: 0.1,
      backgroundColor: 'rgba(139,69,19,0.5)',
      borderColor: 'rgba(139,69,19,1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBackgroundColor: 'rgba(139,69,19,1)',
      pointHoverBackgroundColor: 'rgba(139,69,19,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      data: [0,0,0,6.67,6.67,6.67,6.67,6.67,6.67,6.67,6.67,6.67,6.67,6.67,6.67,6.67,8.33,8.33,8.33,10.00,10.00,10.00,11.67,11.67,11.67,13.33,13.33,13.33,15.00,15.00,15.00,16.67,16.67,16.67,18.33,18.33,18.33,20.00,20.00,20.00,21.67,21.67,21.67,23.33,23.33,23.33,25.00,25.00]
    },
    {
      label: 'Great Harbour',
      fill: true,
      lineTension: 0.1,
      backgroundColor: 'rgba(255,69,0,0.5)',
      borderColor: 'rgba(255,69,0,1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBackgroundColor: 'rgba(255,69,0,1)',
      pointHoverBackgroundColor: 'rgba(255,69,0,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      data: [0,0,0,0,0,0,0,0,0,0,0,18.00,18.00,18.00,45.00,45.00,45.00,72.00,72.00,72.00,99.00,99.00,99.00,126.00,126.00,126.00,153.00,153.00,153.00,180.00,180.00,180.00,180.00,180.00,180.00,180.00,180.00,180.00,180.00,180.00,180.00,180.00,180.00,180.00,180.00,180.00,180.00,180.00]
    },
    {
      label: 'Ecosystem',
      fill: true,
      lineTension: 0.1,
      backgroundColor: 'rgba(47,79,79,0.5)',
      borderColor: 'rgba(47,79,79,1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBackgroundColor: 'rgba(47,79,79,1)',
      pointHoverBackgroundColor: 'rgba(47,79,79,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      data: [13.37,13.37,13.37,26.74,26.74,26.74,26.74,40.11,40.11,40.11,40.11,53.48,53.48,53.48,53.48,66.85,66.85,66.85,66.85,80.23,80.23,80.23,80.23,93.60,93.60,93.60,93.60,106.97,106.97,106.97,106.97,160.45,160.45,160.45,160.45,213.93,213.93,213.93,213.93,267.42,267.42,267.42,267.42,267.42,267.42,267.42,267.42,267.42]
    },
    {
      label: 'Foundation',
      fill: true,
      lineTension: 0.1,
      backgroundColor: 'rgba(111,102,93,0.5)',
      borderColor: 'rgba(111,102,93,1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBackgroundColor: 'rgba(111,102,93,1)',
      pointHoverBackgroundColor: 'rgba(111,102,93,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      data: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,32.40,32.40,32.40,64.80,64.80,64.80,97.20,97.20,97.20,129.60,129.60,129.60,194.40,194.40,194.40,259.20,259.20,259.20,324.00,324.00,324.00,324.00,324.00,324.00,324.00]
    },
    {
      label: 'Exchanges',
      fill: true,
      lineTension: 0.1,
      backgroundColor: 'rgba(161,189,234,0.5)',
      borderColor: 'rgba(161,189,234,1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBackgroundColor: 'rgba(161,189,234,1)',
      pointHoverBackgroundColor: 'rgba(161,189,234,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      data: [0,0,0,0,97.51,97.51,97.51,97.51,97.51,97.51,97.51,97.51,97.51,97.51,97.51,97.51,97.51,97.51,97.51,97.51,97.51,97.51,97.51,97.51,97.51,97.51,97.51,97.51,97.51,97.51,97.51,97.51,97.51,97.51,97.51,97.51,97.51,97.51,97.51,97.51,97.51,97.51,97.51,97.51,97.51,97.51,97.51,97.51]
    }
  ]
};

const lineOptions = {
  plugins: {
    datalabels: {
      display: false,
    },
  },
  legend: {
    position: 'bottom'
  },
  scales: {
    xAxes: [{
      gridLines: {
        drawBorder: true,
        display: false
      },
      scaleLabel: {
        display: true,
        labelString: 'Months from genesis'
      }
    }],
    yAxes: [{
      stacked: true,
      gridLines: {
        drawBorder: true,
        display: false
      },
      ticks: {
        callback: function(value) {
          return numeral(value).format('0,0') + ' m';
        }
      },
      scaleLabel: {
        display: true,
        labelString: 'Total TEM in circulation'
      }
    }]
  },
  maintainAspectRatio: false
};

class Tokenomics extends React.Component {
  state = {
    totalCirculation: '1,500,000,000',
    tokensInSupply: '261,044,322',
    allowedToSold: '500,000,000',
    exchanges: '75,948,274',
    top100: []
  }

  static formatNumber(value) {
    if (value === 0) {
      return 0;
    }

    if (!value) {
      return '';
    }

    const res = numeral(value);

    return res.format('0,0');
  }

  getTokensOnExchanges() {
    const address = '0361ac903cd264dab19d288204d2cdee731d081c6cda025cd5f2cb7f46562cd301';

    axios
      .get(`https://explorer.temtum.com/api/v1/address/${address}/balance`)
      .then((res) => {
        if (res.data && res.data.balance) {
          const balance = Tokenomics.formatNumber(res.data.balance);

          this.setState({ exchanges: balance });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  getTop100() {
    axios
      .get('https://admin.temtum.com/api/v1/addresses/top100')
      .then((res) => {
        if (res.data) {
          const top100 = res.data;

          this.setState({ top100 });
        }
      })
      .catch((err) => {
        console.error(err);
      })
  }

  getTokensInSupply() {
    axios
      .get('https://stat.temtum.com/totalsupply')
      .then((res) => {
        if (res.data) {
          const tokensInSupply = res.data;

          this.setState({ tokensInSupply });
        }
      })
      .catch((err) => {
        console.error(err);
      })
  }

  componentDidMount() {
    //this.getTokensOnExchanges();
    this.getTop100();
  }

  render() {
    return (
      <Layout>
        <div className="skipScroll">
          <Helmet
            title={'Tokenomics | temtum'}
            meta={[
              {
                name: 'description',
                content: 'temtum (TEM) is a market leading cryptocurrency, founded by Richard Dennis, developed on the Temporal Blockchain from Dragon Infosec.'
              }
            ]}
          />
          <div className="hd-space"/>
          <div className="page">
            <section className="text-white skipScroll bg2X bg-research">
              <div className="darker video-overlay"/>
              <div className="wow3 video-overlay"/>
              <div className="container">
                <div className="row" data-aos="fade-in" data-aos-duration="1200">
                  <div className="col col-12 col-sm-6 col-md-6">
                    <h3 className="display-2 font-weight-normal mb-3 headline red pb-3">temtum Tokenomics</h3>
                    <p className="lead">
                      Take a look at the distribution of tokens.
                    </p>
                  </div>
                  <div className="col col-12 col-sm-6 col-md-6">
                    <p className="lead">
                      Currently only whole temtum (TEM) is able to be sent over the network - while the ability to send fractions of temtum has been designed and implemented into the network it has not yet been made live.
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <section className="bg-white text-dark skipScroll pb-0">
              <div className="container">
                <div className="row" data-aos="fade-in" data-aos-duration="1200">
                  <div className="col col-12 col-sm-12 col-md-9 text-center mx-auto ">
                    <h3 className="display-2 font-weight-normal mb-3">
                      Tokens
                      <strong>Info</strong>
                    </h3>
                    <p className="leadX pb-1">
                      Foundation, Ecosystem, Private sale and some Great Harbour tokens will not be allowed to be sold via exchanges when unlocked.
                    </p>
                  </div>
                </div>
                <div className="row" data-aos="fade-in" data-aos-duration="1200">
                  <div className="col col-12 col-sm-12 col-md-7 mx-auto text-center">
                    <div className="container">
                      <div className="row text-center">
                        <div className="col">
                          <div className="countBox">
                            <div className="timer count-title count-title-mod">
                              <span className="counter" data-counterup-time="1000" data-counterup-delay="200" data-counterup-beginat="0">
                                {this.state.totalCirculation} TEM
                              </span>
                            </div>
                            <p className="count-text">Tokens circulation</p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="countBox">
                            <div className="timer count-title count-title-mod">
                              <span className="counter" data-counterup-time="1000" data-counterup-delay="400" data-counterup-beginat="0">
                                {this.state.allowedToSold} TEM
                              </span>
                            </div>
                            <p className="count-text">Allowed to sold on exchanges</p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="countBox">
                            <div className="timer count-title count-title-mod">
                              <span className="counter" data-counterup-time="1000" data-counterup-delay="600" data-counterup-beginat="0">
                                {this.state.tokensInSupply} TEM
                              </span>
                            </div>
                            <p className="count-text">Tokens in supply</p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="countBox">
                            <div className="timer count-title count-title-mod">
                              <span className="counter" data-counterup-time="1000" data-counterup-delay="600" data-counterup-beginat="0">
                                {this.state.exchanges} TEM
                              </span>
                            </div>
                            <p className="count-text">Exchanges circulation</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="bg-white text-dark skipScroll pb-0">
              <div className="container my-5X">
                <div className="row" data-aos="fade-in" data-aos-duration="1200">
                  <div className="col col-6 col-sm-6 col-md-6 mx-auto text-center">
                    <h3 className="display-3 font-weight-bold mb-4">Token Distribution</h3>
                  </div>

                  <div className="col-lg-12 mx-auto">
                    <Pie
                      data={pieData}
                      options={pieOptions}
                      height={500}
                    />
                  </div>
                </div>
              </div>
            </section>

            <section className="bg-white text-dark skipScroll pb-0">
              <div className="container mt-5">
                <div className="row" data-aos="fade-in" data-aos-duration="1200">
                  <div className="col col-6 col-sm-6 col-md-6  mx-auto  text-center">
                    <h3 className="display-3 font-weight-bold mb-4">Release Schedule</h3>
                  </div>

                  <div className="col-lg-12 mx-auto">
                    <Line
                      data={lineData}
                      options={lineOptions}
                      height={500}
                    />
                  </div>
                </div>
              </div>
            </section>

            <section className="bg-white text-dark skipScroll pb-0">
              <div className="container">
                <div className="row" data-aos="fade-in" data-aos-duration="1200">
                  <div className="col col-6 col-sm-6 col-md-6  mx-auto  text-center">
                    <h3 className="display-3 font-weight-bold mb-4">Top 100 list</h3>
                  </div>

                  <table className="table">
                    <thead>
                    <tr>
                      <td>#</td>
                      <td>Address</td>
                      <td>Balance</td>
                      <td>UTXO count</td>
                    </tr>
                    </thead>
                    <tbody>
                      {this.state.top100.map((item, i) => (
                        <tr key={`top100-key-${i}`}>
                          <td>{i + 1}</td>
                          <td>
                            <a href={`https://explorer.temtum.com/address/${item.address}`} target="_blank" rel="noopener noreferrer">{item.address}</a>
                          </td>
                          <td>{item.balance}</td>
                          <td>{item.utxo.length}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </section>

            <section className="bg-white text-dark skipScroll">
              <div className="container">
                <div className="row" data-aos="fade-in" data-aos-duration="1200">
                  <div className="col col-6 col-sm-6 col-md-6  mx-auto  text-center">
                    <h3 className="display-3 font-weight-bold mb-4">Reserve Allocation</h3>
                  </div>

                  <table className="table">
                    <thead>
                    <tr>
                      <td>#</td>
                      <td>Address</td>
                      <td>Balance</td>
                      <td>UTXO count</td>
                    </tr>
                    </thead>
                    <tbody>
                    {reserveAllocation.map((item, i) => (
                      <tr key={`reserve-key-${i}`}>
                        <td>{i + 1}</td>
                        <td>
                          <a href={`https://explorer.temtum.com/address/${item.address}`} target="_blank" rel="noopener noreferrer">{item.address}</a>
                        </td>
                        <td>{item.balance}</td>
                        <td>{item.utxo.length}</td>
                      </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </section>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Tokenomics;
